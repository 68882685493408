import React, { FC, useCallback } from 'react'
import router, { useRouter } from 'next/router'
import { Trans } from 'next-i18next'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'
import { Sizes } from 'ui/button/types'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'

import { logError, noop } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'
import { useWishlistData } from 'lib/context/wishlist-context'

import { EVENTS } from 'lib/constants/events'
import { STORAGE_WISHLISTED_PRODUCT_ID } from 'lib/constants'

import s from './styles.module.scss'

interface WishlistProps {
  productId: string
  product?: Product
  destinationId?: string
  variant?: 'vertical' | 'with-label' | 'secondary-with-label' | 'default' | 'static'
  buttonSize?: Sizes
  trackEvent?: TrackEventType
  refetchProduct?: () => void
  onProductAddedToWishlist?: (isAddToWishlist?: boolean) => void
}

const Wishlist: FC<WishlistProps> = ({
  productId,
  destinationId,
  variant = 'default',
  buttonSize = 'medium',
  trackEvent: trackEventProp,
  refetchProduct = noop,
  onProductAddedToWishlist,
  product,
}) => {
  const { t } = useTranslation('common')
  const { authModal, isLoggedIn } = useAuth()
  const { toast, trackEvent: trackEventRef } = useAppData()
  const wishlist = useWishlistData()
  const { pathname, query } = useRouter()

  const trackEvent = trackEventProp || trackEventRef.current || noop

  const quickUnwishlist = `/wishlist/[wishlistId]` === pathname

  const isWishlisted = wishlist.isWishlisted(productId)

  const showToastMessage = useCallback(
    (i18nKey, values) =>
      toast.success({
        content: (
          <span>
            <Trans
              i18nKey={i18nKey}
              values={values}
              components={{
                bold: <span className="bold" />,
              }}
            />
          </span>
        ),
        id: 'wishlist-toast',
      }),
    [toast]
  )

  const handleWishlistIconClick = async (event: React.MouseEvent) => {
    try {
      event.stopPropagation()
      event.preventDefault()

      trackEvent({
        attributeId: isWishlisted ? EVENTS.REMOVE : EVENTS.ADD,
        attributeType: `${EVENTS.WISHLIST}_${EVENTS.ATTRIBUTES_TYPE.ICON}`,
        destinationId,
      })

      if (isLoggedIn) {
        wishlist.modal.updateData({
          product,
          productId,
          destinationId,
          onProductAddedToWishlist,
          onComplete: () => {
            refetchProduct()
          },
        })

        if (!isWishlisted) {
          // First, try to auto save product to Wishlist (BE will decide)
          wishlist.saveToWishlist({
            productId,
            onSuccess: ({ toCreate, wishlistName }) => {
              // If destination of the product is not already assigned to
              // any of the wishlists, So user has to create new wishlist
              // here, BE will return `toCreate: true`
              if (toCreate) {
                // Set data for create WL flow
                wishlist.modal.updateData((curr: any) => ({
                  ...curr,
                  autoSuggestName: true,
                  nextAction: 'none',
                  onComplete: (data: any) => {
                    if (!data?.wishlistId) return

                    showToastMessage('msg.savedTo', { count: 1, wishlistName: data.wishlistName })
                  },
                }))

                wishlist.modal.setActive('create')
              } else {
                // Auto Saved to wishlist from BE
                toast.success({
                  content: (
                    <span className={s.toastContainer}>
                      <span>
                        <Trans
                          i18nKey="msg.savedTo"
                          values={{ count: 1, wishlistName }}
                          components={{
                            bold: <span className="bold" />,
                          }}
                        />
                      </span>
                      <Button
                        variant="link-tertiary"
                        size="small"
                        className="ml-4"
                        onClick={handleOnChangeWishlist}
                      >
                        {t('action.change')}
                      </Button>
                    </span>
                  ),
                  id: 'wishlist-toast',
                })

                if (onProductAddedToWishlist) {
                  onProductAddedToWishlist(true)
                } else {
                  wishlist.onProductAddedToWishlist(product)
                }
              }
            },
            onError: (e) => logError(e),
          })
        } else {
          // Remove item from WL
          if (quickUnwishlist && query.wishlistId) {
            // `Accounts > Wishlit page`, should directly remove item from WL,
            // instead of showing the WL modal
            wishlist.removeItem({
              productId,
              wishlistId: query.wishlistId as string,
              onSuccess: (data) => {
                toast.success({
                  content: (
                    <span className={s.toastContainer}>
                      <span>
                        <Trans
                          i18nKey="msg.removedFrom"
                          values={{ count: 1, wishlistName: data.wishlistName }}
                          components={{
                            bold: <span className="bold" />,
                          }}
                        />
                      </span>
                      <Button
                        variant="link-tertiary"
                        size="small"
                        className="ml-4"
                        onClick={() => {
                          toast.remove('wishlist-toast')

                          wishlist.saveToWishlist({
                            productId,
                            wishlistId: query.wishlistId as string,
                            onSuccess: () => {
                              refetchProduct()
                            },
                            onError: (e) => logError(e),
                          })
                        }}
                      >
                        {t('action.undo')}
                      </Button>
                    </span>
                  ),
                  id: 'wishlist-toast',
                })

                refetchProduct()
              },
            })
          } else {
            wishlist.modal.setActive('update')
          }
        }
        return
      }

      if (!isLoggedIn) {
        localStorage.setItem(
          STORAGE_WISHLISTED_PRODUCT_ID,
          JSON.stringify({
            productId,
            destinationId,
          })
        )
        const redirectUrl = router.asPath
        authModal.open({ screen: 'signup', redirectUrl })
        return
      }
    } catch (e) {
      logError(e)
    }
  }

  const handleOnChangeWishlist = useCallback(() => {
    wishlist.modal.updateData((curr: any) => ({
      ...curr,
      nextAction: 'none',
      onComplete: (data: any) => {
        // If updated the Wishlist items, it wont have wishlistId
        if (!data?.wishlistId) return

        showToastMessage('msg.savedTo', { count: 1, wishlistName: data.wishlistName })
      },
    }))

    toast.remove('wishlist-toast')

    wishlist.modal.setActive('update')
  }, [wishlist.modal, toast, showToastMessage])

  return (
    <>
      {variant === 'vertical' ? (
        <div
          className={cn(s.btn, { [s.vertical]: variant === 'vertical', [s.selected]: isWishlisted })}
          onClick={handleWishlistIconClick}
        >
          <Icon
            name={isWishlisted ? 'heart-fill' : 'heart'}
            className={cn({ selected: isWishlisted, animate: !isWishlisted })}
          />
          <div className={cn(s.label, 'label-md text-grey-1 bold')}>
            {t(isWishlisted ? 'exclm.saved' : 'action.save')}
          </div>
        </div>
      ) : variant === 'with-label' ? (
        <Button
          variant="link-tertiary"
          iconName={isWishlisted ? 'heart-fill' : 'heart'}
          className={cn(s.btnDesktop, { selected: isWishlisted, animate: !isWishlisted })}
          onClick={handleWishlistIconClick}
        >
          {t(isWishlisted ? 'exclm.saved' : 'action.save')}
        </Button>
      ) : variant === 'secondary-with-label' ? (
        <Button
          variant="secondary"
          size={buttonSize}
          className={cn(s.btnSecondary, 'uppercase', { selected: isWishlisted, animate: !isWishlisted })}
          onClick={handleWishlistIconClick}
        >
          {t(isWishlisted ? 't.saved' : 'action.save')}
          <Icon
            size="semi-medium"
            name={isWishlisted ? 'heart-fill' : 'heart'}
            className={cn({ selected: isWishlisted, animate: !isWishlisted })}
          />
        </Button>
      ) : variant === 'static' ? (
        <button onClick={handleWishlistIconClick} tabIndex={-1}>
          <Icon
            name={isWishlisted ? 'heart-fill' : 'heart'}
            className={cn('cursor-pointer', 'border-white', isWishlisted ? 'selected' : 'grey-fill-light', {
              animate: !isWishlisted,
            })}
          />
        </button>
      ) : (
        <button className={s.heartBtn} onClick={handleWishlistIconClick} tabIndex={-1}>
          <Icon
            name={isWishlisted ? 'heart-fill' : 'heart'}
            className={cn('cursor-pointer', 'border-white', isWishlisted ? 'selected' : 'grey-fill-light', {
              animate: !isWishlisted,
            })}
          />
        </button>
      )}
    </>
  )
}

export { Wishlist }
